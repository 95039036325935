<template>
  <page-view>
    <section slot="content">
      <v-row class="mt-10">
        <v-col lg="4" md="6" offset-md="3" offset-lg="4">
          <validation-observer ref="form" v-slot="{  }">
            <form class="p-8 login-tabs-container">
              <validation-provider vid="email" name="email" rules="required|email" v-slot="{ errors, classes }">
                <v-text-field
                  outlined
                  :class="classes"
                  v-model="data.email"
                  :error-messages="errors"
                  label="email"
                  required
                ></v-text-field>
              </validation-provider>

              <validation-provider vid="password" name="пароль" rules="required|min:8" v-slot="{ errors, classes }">
                <v-text-field
                  outlined
                  v-model="data.password"
                  :error-messages="errors"
                  :class="classes"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  label="пароль"
                  @click:append="showPassword = !showPassword"
                  required
                ></v-text-field>
              </validation-provider>

              <div class="mb-5">
                <router-link to="/">забыли пароль?</router-link>
              </div>
              <div class="d-flex mb-5 align-center">
                <v-btn text class="v-btn--primary mr-5" @click="validate" :loading="loading" :disabled="loading">войти</v-btn>
                <div class="text-gray text-body-2">
                  нажимая "войти", вы подтверждаете согласие на <a href="/files/personal_data.pdf" target="blank">обработку персональных данных</a>,
                  <a href="/" target="blank">политику конфиденциальности</a>, <a href="/" target="blank">правила работы</a> и <a href="/" target="blank">лицензионный договор</a>.
                </div>
              </div>

              <div class="mb-5 text-danger" v-if="error">{{ error }}</div>

            </form>
          </validation-observer>
        </v-col>
      </v-row>
    </section>
  </page-view>
</template>

<script>
import PageView from "@/layouts/components/PageView"

export default {
  name: 'SignInPage',
  components: {
    'page-view': PageView
  },
  data() {
    return {
      data: {
        recaptcha_token: null,
        email: "",
        password: "",
      },
      error: null,
      showPassword: false,
      loading: false
    }
  },
  async mounted() {
    await this.$recaptchaLoaded()
  },
  methods: {
    async login() {
      this.loading = true
      this.data.recaptcha_token = await this.$recaptcha("signup")
      this.$store.dispatch('login', this.data).then(() => {
        this.error = null
        this.$router.push({path: '/pads'})
        this.loading = false
      }).catch((err) => {
        if (err.code === 422 && err.errors) {
          this.$refs.form.setErrors(err.errors)
          if (Object.prototype.hasOwnProperty.call(err.errors,'error')) {
            this.error = err.errors.error[0]
          }
        } else if (err.message) {
          this.error = err.message
        }
        this.loading = false
      })
    },
    validate () {
      this.loading = true
      this.$refs.form.validate().then(success => {
        if (!success) {
          this.loading = false
          return
        }
        this.login()
      })

    }
  }
}
</script>
